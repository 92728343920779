import { createRouter, createWebHashHistory } from "vue-router";
import empty from "@/layout/empty";
import open from "@/layout/open";

const openRoutes = [
  {
    path: "/loginSelect",
    component: open,
    redirect: "/loginSelect",
    name: "loginSelect",
    meta: { title: "登录选择", classify: "loginSelect", icon: "loginBusiness", affix: true, isShow: 0 },
    children: [
      {
        path: "/loginSelect",
        component: () => import("@/views/loginSelect/index"),
        name: "loginSelect",
        meta: { title: "登录选择", classify: "loginSelect", icon: "loginSelect", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/login",
    component: open,
    redirect: "/login",
    name: "login",
    meta: { title: "客户登录", classify: "login", icon: "login", affix: true, isShow: 0 },
    children: [
      {
        path: "/login",
        component: () => import("@/views/login/index"),
        name: "login",
        meta: { title: "客户登录", classify: "login", icon: "login", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/wxLogin",
    component: open,
    redirect: "/wxLogin",
    name: "wxLogin",
    meta: { title: "微信授权登录", classify: "wxLogin", icon: "wxLogin", affix: true, isShow: 0 },
    children: [
      {
        path: "/wxLogin",
        component: () => import("@/views/wxLogin/index"),
        name: "wxLogin",
        meta: { title: "微信授权登录", classify: "wxLogin", icon: "wxLogin", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/loginBusiness",
    component: open,
    redirect: "/loginBusiness",
    name: "loginBusiness",
    meta: { title: "商务人员登录", classify: "loginBusiness", icon: "loginBusiness", affix: true, isShow: 0 },
    children: [
      {
        path: "/loginBusiness",
        component: () => import("@/views/loginBusiness/index"),
        name: "loginBusiness",
        meta: { title: "商务人员登录", classify: "loginBusiness", icon: "loginBusiness", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/regBusiness",
    component: open,
    redirect: "/regBusiness",
    name: "regBusiness",
    meta: { title: "商务人员注册", classify: "regBusiness", icon: "regBusiness", affix: true, isShow: 0 },
    children: [
      {
        path: "/regBusiness",
        component: () => import("@/views/regBusiness/index"),
        name: "regBusiness",
        meta: { title: "商务人员注册", classify: "regBusiness", icon: "regBusiness", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/activity",
    component: empty,
    redirect: "/activity/index",
    name: "activity",
    meta: { title: "活动", classify: "activity", icon: "activity", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/activity/index"),
        name: "activityIndex",
        meta: { title: "活动列表", classify: "activity", icon: "activity", affix: true, isShow: 0 },
      },
      {
        path: "detail",
        component: () => import("@/views/activity/detail"),
        name: "activityDetail",
        meta: { title: "活动", classify: "activity", icon: "activity", affix: true, isShow: 0 },
      },
      {
        path: "detailTest",
        component: () => import("@/views/activity/detailTest"),
        name: "activityDetailTest",
        meta: { title: "活动", classify: "activity", icon: "activity", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/open",
    component: empty,
    redirect: "/open",
    name: "open",
    meta: { title: "开放页面", classify: "open", icon: "open", affix: true, isShow: 0 },
    children: [
      {
        path: "contact",
        component: () => import("@/views/open/contact"),
        name: "contactIndex",
        meta: { title: "联系我们", classify: "contact", icon: "contact", affix: true, isShow: 0 },
      },
      {
        path: "privacy_policy",
        component: () => import("@/views/open/privacy_policy"),
        name: "privacyPolicyIndex",
        meta: {
          title: "用户服务协议和隐私政策",
          classify: "privacy_policy",
          icon: "privacy_policy",
          affix: true,
          isShow: 0,
        },
      },
      {
        path: "gzh",
        component: () => import("@/views/open/gzh"),
        name: "gzh",
        meta: { title: "公众号", classify: "gzh", icon: "gzh", affix: true, isShow: 0 },
      },
    ],
  },
];

const myRoutes = [
  {
    path: "/order",
    component: empty,
    redirect: "/order/index",
    name: "order",
    meta: { title: "我的预约", classify: "order", icon: "order", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/order/index"),
        name: "orderIndex",
        meta: { title: "我的预约", classify: "order", icon: "order", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/promote",
    component: empty,
    redirect: "/promote/index",
    name: "promote",
    meta: { title: "我的推广", classify: "promote", icon: "promote", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/promote/index"),
        name: "promoteIndex",
        meta: { title: "我的推广", classify: "promote", icon: "promote", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/withdrawal",
    component: empty,
    redirect: "/withdrawal/index",
    name: "withdrawal",
    meta: { title: "我的提现", classify: "withdrawal", icon: "withdrawal", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/withdrawal/index"),
        name: "withdrawalIndex",
        meta: { title: "我的提现", classify: "withdrawal", icon: "withdrawal", affix: true, isShow: 0 },
      },
      {
        path: "form",
        component: () => import("@/views/withdrawal/form"),
        name: "withdrawalForm",
        meta: { title: "我的提现", classify: "withdrawal", icon: "withdrawal", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/my",
    component: empty,
    redirect: "/my/index",
    name: "my",
    meta: { title: "我的", classify: "my", icon: "my", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/my/index"),
        name: "myIndex",
        meta: { title: "我的", classify: "my", icon: "my", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/myBusiness",
    component: empty,
    redirect: "/myBusiness/index",
    name: "myBusiness",
    meta: { title: "我的", classify: "myBusiness", icon: "myBusiness", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/myBusiness/index"),
        name: "myBusinessIndex",
        meta: { title: "我的", classify: "myBusiness", icon: "myBusiness", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/promoteBusiness",
    component: empty,
    redirect: "/promoteBusiness/index",
    name: "promoteBusiness",
    meta: { title: "我的推广", classify: "promoteBusiness", icon: "promoteBusiness", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/promoteBusiness/index"),
        name: "promoteBusinessIndex",
        meta: { title: "我的推广", classify: "promoteBusiness", icon: "promoteBusiness", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/shareBusiness",
    component: empty,
    redirect: "/shareBusiness/index",
    name: "shareBusiness",
    meta: { title: "我的分享", classify: "shareBusiness", icon: "shareBusiness", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/shareBusiness/index"),
        name: "shareBusinessIndex",
        meta: { title: "我的分享", classify: "shareBusiness", icon: "shareBusiness", affix: true, isShow: 0 },
      },
    ],
  },
  {
    path: "/password",
    component: empty,
    redirect: "/password/index",
    name: "password",
    meta: { title: "修改密码", classify: "password", icon: "password", affix: true, isShow: 0 },
    children: [
      {
        path: "index",
        component: () => import("@/views/password/index"),
        name: "passwordIndex",
        meta: { title: "修改密码", classify: "password", icon: "password", affix: true, isShow: 0 },
      },
    ],
  },
];

const constantRoutes = [...openRoutes, ...myRoutes];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

export { openRoutes, constantRoutes, router };
