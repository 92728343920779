/*
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 */
import CarNumber from "car-number-vue3";
import "car-number-vue3/lib/carNumber.css";

import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router/index";

import {
  Notify,
  Toast,
  Form,
  Field,
  Cell,
  CellGroup,
  Button,
  Checkbox,
  Popup,
  Picker,
  Swipe,
  SwipeItem,
  Switch,
  NoticeBar,
  Dialog,
  Loading,
} from "vant";

import "./css/style.dev.scss";

// 导航守卫文档：https://next.router.vuejs.org/zh/guide/advanced/navigation-guards.html#%E5%85%A8%E5%B1%80%E8%A7%A3%E6%9E%90%E5%AE%88%E5%8D%AB
const whiteList = [
  "/loginSelect",
  "/login",
  "/loginBusiness",
  "/wxLogin",
  "/regBusiness",
  "/activity/detail",
  "/activity/detailTest",
  "/activity/authBase",
  "/open/gzh",
];
router.beforeEach((to, from, next) => {
  if (whiteList.indexOf(to.path) > -1) {
    next();
  } else {
    console.log("beforeEach", to.path, localStorage.getItem("TOKEN"), localStorage.getItem("userInfo"));
    //next()
    if (!localStorage.getItem("TOKEN") || !localStorage.getItem("userInfo")) {
      localStorage.clear();
      next({ path: "/loginSelect" });
    } else {
      next();
    }
  }
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

createApp(App)
  .use(router)
  .use(Notify)
  .use(Toast)
  .use(Form)
  .use(Field)
  .use(Cell)
  .use(CellGroup)
  .use(Button)
  .use(Checkbox)
  .use(Popup)
  .use(Picker)
  .use(Swipe)
  .use(SwipeItem)
  .use(Switch)
  .use(NoticeBar)
  .use(Dialog)
  .use(Loading)
  .use(CarNumber)
  .mount("#app");
