<template>
  <router-view />
</template>

<script>
// import axios from 'axios';
export default {
  name: 'App',
  components: {
  },
  data() {
      return {
          breadCrumbs: [],
      }
  },
  created(){
    /*localStorage.setItem('userInfo','')
    if(!localStorage.getItem('TOKEN')||!localStorage.getItem('userInfo')){
      localStorage.clear()
      this.$router.push('login');
    }*/
  },
}
</script>

<style>
</style>
