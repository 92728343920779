<template>
  <div class="main-open">
    <div class="main-open-bg2"></div>
    <router-view/>
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {
    document.getElementById("body").className = "body-height-100"
  },
}
</script>

<style lang="scss" scoped>
@import '../css/_var.scss';
/*开放页面 登录 注册*/

@media screen and (max-width: 1920px) {

}
</style>
